import React, { useEffect, useState, useRef } from "react"
import Block from "./block"
import config, { firebaseInit } from "../../config"
import { getSuggestions, getOneBlock } from "../../api/content"
import FilterButton from "../buttons/FilterButton.js"
import {
  ContactSupportOutlined,
  SettingsBackupRestoreRounded,
} from "@material-ui/icons"
import { navigate } from "gatsby-link"
import SignInModal from "../modals/SignInModal"
import PaymentMethodModal from "../modals/PaymentMethodModal"
import NewPaymentFormModal from "../modals/NewPaymentFormModal"
import SubscriptionMessageModal from "../modals/SubscriptionMessageModal"
import CvvModal from "../forms/payments/cvvModal"
import showPerfilForm from "../modals/PerfilFormModal"
import PerfilForm from "../modals/PerfilFormModal"
import firebase from "firebase"
import DeleteMessageModal from "../modals/DeleteMessageModal"
import { StaticImage } from "gatsby-plugin-image"
import PremiumButton from "../../components/buttons/PremiumButton"
import { getUserAlerts, markAlertAsViewed } from "../../api/alertas"
import { getCarrouselForUser, getCardInfo } from "../../api/carrousel"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons"

const Section = ({
  section,
  userHasSuscription,
  suscription,
  params,
  onFilterChange,
}) => {
  // let sectionId = "60f97c29ce3f0e0e5c92d2a3"
  const profileUri = config.serverUri
  const [sectionId] = React.useState(section.titulo)
  // console.log(section.titulo)
  const [suggestions, setSuggestions] = React.useState({})
  const [SectionFiltrada, setSectionFiltrada] = React.useState(null)
  const [showSignInForm, setShowSignInForm] = React.useState(false)
  const [showPaymentMethod, setShowPaymentMethod] = React.useState(false)
  const [showNewPayment, setShowNewPayment] = React.useState(false)
  const [showSuccessful, setShowSuccessful] = React.useState(false)
  const [showCvvHelp, setShowCvvHelp] = React.useState(false)
  const [showPerfilForm, setShowPerfilForm] = React.useState(false)
  const [ispwa, setIspwa] = React.useState(null)
  const [userSuscription, setUserSuscription] = React.useState(null)
  const [showDeleteMessage, setShowDeleteMessage] = React.useState(null)
  const [deleteConfirmation, setDeleteConfirmation] = React.useState(null)
  const [isApplePay, setIsApplePay] = React.useState(false)
  const [searchBlock, setSearchBlock] = React.useState(null)
  const [userId, setUserId] = useState(null)
  const [showRecommended, setShowRecommended] = React.useState(true)
  const [showOtherBlock, setShowOtherBlock] = React.useState(true)
  const [alerts, setAlerts] = useState([])
  const [currentAlertIndex, setCurrentAlertIndex] = useState(0)
  const [alertViewed, setAlertViewed] = useState(false)
  const [banners, setBanners] = useState([])
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0)
  const [userFirebase, setUserFirebase] = useState(undefined)
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const timerRef = useRef(null)
  const [isAuthLoading, setIsAuthLoading] = useState(true)

  useEffect(() => {
    getOneBlock(section.id).then(data => {
      setSearchBlock(data)
    })

  }, [])

  const onViewAllClick = () => {
    setShowOtherBlock(false)
  }

  // Solo se ejecuta una vez

  const location = ""

  const handleFilter = nuevoBloque => {
    setSectionFiltrada(nuevoBloque)
    onFilterChange(nuevoBloque.id)
  }
  useEffect(() => {
    if (!userId) return

    getSuggestions(userId, section.id).then(data => {
      if (section.seccion) {
        setShowRecommended(false)
      }
      data._id = section.id
      data.titulo = "Recomendados para ti"
      data.isBlock = false
      setSuggestions(data)
    })

    if (location == "") {
      setIspwa(false)
    } else {
      setIspwa(true)
    }

    // Lógica para obtener las alertas del usuario
    getUserAlerts(userId).then(data => {
      if (data.length > 0) {
        setAlerts(data)
      }
    })
  }, [userId])

  useEffect(() => {
    setIsUserLoaded(false)
    setUserFirebase(undefined)

    const getCurrentUser = async user => {
      if (user) {
        console.log(user)
        setUserFirebase(user)

        try {
          const response = await fetch(
            `${config.serverUri}/users?email=${user.email}`
          )

          if (response.ok) {
            const data = await response.json()
            const userAccount = data[0]
            setUserId(userAccount.id)
            setIsUserLoaded(true)

            // Verificar y actualizar el campo "tecnologia"
            if (userAccount.tecnologia !== "SITIO WEB") {
              try {
                const updateResponse = await fetch(
                  `${config.serverUri}/users/${userAccount.id}`,
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ tecnologia: "SITIO WEB" }),
                  }
                )

                if (!updateResponse.ok) {
                  console.error("Error al actualizar el campo tecnologia")
                }
              } catch (updateError) {
                console.error("Error en la actualización: ", updateError)
              }
            }
          } else {
            setIsUserLoaded(true)
            console.error("Error al obtener el usuario por correo electrónico")
          }
        } catch (error) {
          console.error("Error en la petición: ", error)
        }
      } else {
        console.log("No hay usuario autenticado")
        setIsUserLoaded(true) // Marcar como cargado aunque no haya usuario autenticado
      }

      // Marcar que la autenticación ya no está cargando
      setIsAuthLoading(false)
    }

    // Usar onAuthStateChanged para asegurarse de que se restaura el estado de autenticación
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      getCurrentUser(user)
    })

    // Limpiar el suscriptor cuando se desmonte el componente
    return () => unsubscribe()
  }, []) // Solo se ejecuta una vez

  // Segundo useEffect para banners
  useEffect(() => {
    if (!isUserLoaded || !section) {
      // No ejecutar si el usuario no ha sido cargado o si la sección no está definida
      return
    }

    // Limpiar los banners al iniciar
    setBanners([])
    console.log("useEffect para banners ejecutado")

    // Función para obtener los banners
    const fetchCarrouselData = async () => {
      try {
        const data = await getCarrouselForUser(section.id, userId)
        console.log("Data recibida:", data) // Verificar el formato de la data

        if (data.status === "Success" && Array.isArray(data.data)) {
          console.log("Data es válida y es un array")

          // Procesar los banners y obtener detalles adicionales si es necesario
          const bannersWithDetails = await Promise.all(
            data.data.map(async banner => {
              console.log("Procesando banner:", banner) // Log para cada banner
              if (banner.haveRoute && banner.routeType === "Interno") {
                console.log(
                  "El banner tiene ruta, intentando obtener detalles:",
                  banner.route
                )
                try {
                  const cardDetails = await getCardInfo(banner.route)
                  console.log("Detalles de la tarjeta obtenidos:", cardDetails)
                  return { ...banner, cardDetails }
                } catch (error) {
                  console.error("Error al obtener la tarjeta:", error)
                  return banner
                }
              } else {
                console.log(banner)
                return banner
              }
            })
          )

          // Solo actualizar los banners si hay datos nuevos
          setBanners(bannersWithDetails)

          console.log(section && section.portrait && section.portrait.url)
          console.log(section.seccion.portrait.url)
        } else {
          console.error(
            "Estructura de data no válida o data.data no es un array"
          )
        }
      } catch (error) {
        console.error("Error en Promise.all:", error)
      }
    }

    fetchCarrouselData()
  }, [isUserLoaded, section]) // Dependencias: `isUserLoaded`, `section` y `userId`

  const handleBannerClick = banner => {
    if (banner.haveRoute && banner.cardDetails) {
      const { tipoDeContenido, entrenamiento, audio, plan, live } =
        banner.cardDetails

      // Buscamos en las propiedades disponibles el valor de url_slug
      const url_slug =
        entrenamiento?.url_slug ||
        audio?.url_slug ||
        plan?.url_slug ||
        live?.url_slug

      if (url_slug) {
        // Redirigimos al URL generado con tipoDeContenido y url_slug
        const contentUrl = `/content/${tipoDeContenido}s/${url_slug}`
        navigate(contentUrl)
      } else {
        console.error("No se encontró un url_slug válido.")
      }
    } else if (banner.routeType === "Externo") {
      window.open(banner.route, "_blank", "noopener")
    } else {
      navigate(banner.route)
    }
  }

  const handleViewMore = alert => {
      if (!alertViewed) {
        markAlertAsViewed(userId, alert._id)
      setAlertViewed(true)
      }
    window.open(alert.link, "_blank")
  }

  const handleCloseAlert = () => {
    if (!alertViewed) {
      markAlertAsViewed(userId, alerts[0]._id)
      setAlertViewed(true)
    }
    setAlerts([])
  }

  const nextBanner = () => {
    setCurrentBannerIndex(prevIndex => (prevIndex + 1) % banners.length)
  }

  const prevBanner = () => {
    setCurrentBannerIndex(
      prevIndex => (prevIndex - 1 + banners.length) % banners.length
    )
  }

  const resetTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
    timerRef.current = setInterval(() => {
      nextBanner()
    }, 3000)
  }

  const handleManualBannerChange = newIndex => {
    setCurrentBannerIndex(newIndex)
    resetTimer()
  }

  useEffect(() => {
    resetTimer()
    return () => clearInterval(timerRef.current)
  }, [banners.length])

  const fakeSuggestionsBlock = {
    titulo: "Recomendaciones para ti",
    createdAt: new Date().toISOString(),
    id: null,
    cards: suggestions,
  }

  return (
    <div>
      <header>
        <div>
          <div
            className="flex relative overflow-hidden"
            style={{ width: "100%" }}
          >
            {/* Mostrar imagen de sección si no hay banners */}
            {banners.length === 0 ? (
              section && section.portrait && section.portrait.url ? (
                <div
                  className="flex relative"
                  style={{ height: 240, width: "100%" }}
                >
                  <img
                    className="absolute"
                    src={
                      section.portrait.url.trim().startsWith("http")
                        ? section.portrait.url.trim()
                        : config.serverUri + section.portrait.url.trim()
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={section.name}
                  />
                </div>
              ) : (
                <div
                  className="flex relative"
                  style={{ height: 240, width: "100%" }}
                >
                  <img
                    className="absolute"
                    src={
                      section.seccion.portrait.url.trim().startsWith("http")
                        ? section.seccion.portrait.url.trim()
                        : config.serverUri + section.seccion.portrait.url.trim()
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    alt={section.name}
                  />
                </div>
              )
            ) : (
              <>
                {/* Flecha izquierda */}
                {currentBannerIndex > 0 && (
                  <div
                    className="absolute left-8 top-1/2 transform -translate-y-1/2 z-10 shadow-lg"
                    onClick={() => {
                      prevBanner()
                      resetTimer() // Resetear el temporizador al hacer clic manualmente
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <KeyboardArrowLeft
                      style={{
                        fontSize: 90, // Flechas mucho más grandes
                        color: "white",
                      }}
                    />
                  </div>
                )}

                {/* Contenedor de las imágenes con animación */}
                <div
                  className="flex transition-transform duration-700 ease-in-out relative"
                  style={{
                    transform: `translateX(-${currentBannerIndex * 100}%)`,
                    width: `${banners.length * 100}%`, // Ancho proporcional al número de banners
                    height: "auto", // El contenedor se adapta al tamaño de la imagen
                  }}
                >
                  {banners.map((banner, index) => (
                    <div
                      key={index}
                      className="flex-shrink-0 relative"
                      style={{
                        width: "100%",
                        position: "relative",
                        cursor:
                          banner.haveRoute && !banner.showRouteButton
                            ? "pointer"
                            : "default",
                        height: "auto", // Ajustar altura según la imagen
                      }}
                      onClick={
                        banner.haveRoute && !banner.showRouteButton
                          ? () => handleBannerClick(banner)
                          : null
                      }
                    >
                      {/* Imagen con lógica para hacerla clicable si banner.showRouteButton es false pero haveRoute es true */}
                      <img
                        className="w-full object-cover"
                        src={
                          banner?.desktopImage.url.trim().startsWith("http")
                            ? banner?.desktopImage.url.trim()
                            : config.serverUri + banner?.desktopImage.url.trim()
                        }
                        alt={section.name}
                        style={{
                          position: "relative",
                          zIndex: 0,
                          height: "auto", // La imagen debe determinar la altura
                          marginBottom: "0px",
                        }}
                      />

                      {/* Contenedor centrado para el título y el botón */}
                      <div
                        className="absolute inset-0 flex flex-col justify-center items-center sm:pb-[50px]"
                        style={{ zIndex: 2 }}
                      >
                        {/* Título centrado con sombra */}
                        {banner.title && (
                          <p
                            className="text-white text-2xl font-bold mb-4"
                            style={{
                              textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            {banner.title}
                          </p>
                        )}

                        {/* Botón centrado, redondo, más pequeño y con sombra */}
                        {banner.showRouteButton && (
                          <button
                            onClick={() => handleBannerClick(banner)}
                            className="bg-black text-white py-1 px-4 rounded-full shadow-lg hover:bg-gray-900"
                          >
                            Ingresar ahora
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>

                {/* Flecha derecha */}
                {currentBannerIndex < banners.length - 1 && (
                  <div
                    className="absolute right-8 top-1/2 transform -translate-y-1/2 z-10 shadow-lg"
                    onClick={() => {
                      nextBanner()
                      resetTimer() // Resetear el temporizador al hacer clic manualmente
                    }}
                    style={{
                      background: "none",
                      border: "none",
                      padding: "15px",
                      cursor: "pointer",
                    }}
                  >
                    <KeyboardArrowRight
                      style={{
                        fontSize: 90, // Flechas mucho más grandes
                        color: "white",
                      }}
                    />
                  </div>
                )}

                {/* Indicadores de los banners (círculos) */}
                <div
                  className="absolute flex justify-center w-full bottom-4"
                  style={{ zIndex: 2 }} // Los círculos se colocan sobre la imagen
                >
                  {banners.map((_, index) => (
                    <div
                      key={index}
                      className={`h-3 w-3 mx-2 rounded-full cursor-pointer ${
                        index === currentBannerIndex
                          ? "bg-sc-primary"
                          : "bg-gray-300 hover:bg-gray-400"
                      }`}
                      onClick={() => handleManualBannerChange(index)}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </header>

      <main className="p-8 mb-32">
        <div className="filters-section">
          <FilterButton
            section={section}
            onSectionFilterChange={e => handleFilter(e)}
          ></FilterButton>
        </div>

        {userHasSuscription === "premium" && showRecommended ? (
          <Block
            key={fakeSuggestionsBlock._id}
            subtree={suggestions.isBlock}
            block={fakeSuggestionsBlock}
            sectionId={"Recomendados"}
            userHasSuscription={userHasSuscription ?? null}
            onViewAllClick={onViewAllClick}
          />
        ) : null}

        {searchBlock !== null ? (
          <>
            <Block
              subtree={true}
              block={searchBlock}
              sectionId={searchBlock.id}
              userHasSuscription={userHasSuscription ?? null}
            ></Block>
          </>
        ) : null}

        {/* Solo funciona en "index", en sections[name] no se renderiza */}

        {showOtherBlock && SectionFiltrada !== null ? (
          <>
            {SectionFiltrada.blocks.map((bloque, key) => {
              return (
                <Block
                  key={key}
                  subtree={SectionFiltrada.isBlock}
                  block={bloque}
                  sectionId={SectionFiltrada.id}
                  userHasSuscription={userHasSuscription ?? null}
                ></Block>
              )
            })}
          </>
        ) : null}

        {showOtherBlock &&
        section.blocks &&
        section.blocks.length > 0 &&
        SectionFiltrada === null ? (
          <>
            {section.blocks.map((bloque, key) => {
              return (
                <>
                  <Block
                    key={key}
                    subtree={section.isBlock}
                    block={bloque}
                    sectionId={section.id}
                    userHasSuscription={userHasSuscription ?? null}
                  ></Block>
                </>
              )
            })}
          </>
        ) : null}
      </main>
      {alerts.length > 0 && section.titulo?.toLowerCase() == "home" && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="fixed inset-0 bg-black opacity-50"></div>
          <div className="bg-transparent	">
            <div className="relative bg-white w-96 h-auto rounded-lg">
              <div className="flex justify-between items-start px-2">
                <div className="flex-grow text-center">
                  <StaticImage
                    width={96}
                    alt="Shift Logo"
                    src="../../images/logo_black.png"
                    className="my-4 mx-auto"
                  />
                </div>
                <button
                  className="text-4xl font-medium text-black"
                  onClick={handleCloseAlert}
                >
                  &times;
                </button>
              </div>

              {/* Imagen de la alerta */}
              <div className="relative overflow-hidden">
                <img
                  src={alerts[0].image.url}
                  alt={alerts[0].title}
                  className="w-full h-96 object-cover rounded-b-lg mb-0"
                />
              </div>
            </div>
            {/* Condicional para mostrar el botón solo si alerts[0].link no es nulo */}
            {alerts[0].link && (
              <div className="flex justify-center w-full bg-transparent relative mt-4">
                <button
                  className="bg-sc-primary shadow-custom-shadow py-3 px-4 rounded-xl w-48 rounded-full text-white text-base"
                  onClick={() => handleViewMore(alerts[0])}
                >
                  Más información
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      <SignInModal
        show={showSignInForm}
        onClose={() => setShowSignInForm(false)}
      />
      <PaymentMethodModal
        show={showPaymentMethod}
        onClose={() => setShowPaymentMethod(false)}
        newPaymentMethod={value => {
          setShowNewPayment(value)
          setShowPaymentMethod(false)
        }}
        message={value => {
          setShowSuccessful(value)
          suscription("premium")
          setShowPaymentMethod(false)
        }}
        setIsApplePay={setIsApplePay}
      />
      <NewPaymentFormModal
        show={showNewPayment}
        onClose={() => setShowNewPayment(false)}
        back={() => {
          setShowPaymentMethod(true)
          setShowNewPayment(false)
        }}
        cvvHelp={() => setShowCvvHelp(true)}
        messageOk={value => {
          suscription("premium")
          setShowSuccessful(value)
          setShowNewPayment(false)
        }}
      />
      <SubscriptionMessageModal
        isApplePay={isApplePay}
        show={showSuccessful}
        onClose={() => {
          setShowSuccessful(false)
          setShowPerfilForm(true)
        }}
      />
      <CvvModal show={showCvvHelp} onClose={() => setShowCvvHelp(false)} />

      {!ispwa && userHasSuscription === "premium" ? (
        <PerfilForm
          show={showPerfilForm}
          ispwaComponent={ispwa}
          onClose={() => setShowPerfilForm(false)}
        />
      ) : null}
    </div>
  )
}

export default Section
